import { useDebounceFn } from '@vueuse/core'

// Maps to store debounced and throttled functions by key
const debounceMap = new Map()
const throttleMap = new Map()

const SHOP_REQUEST_TIMEOUT = 5 * 60 * 1000 // 5 min, before we allow to refetch data for the same query params

export function useOptimizations() {
  // Debounce function with key
  const debounceByKey = (key, fn, delay = 500) => {
    // Check if a debounced function for this key already exists
    if (debounceMap.has(key)) {
      return debounceMap.get(key)(fn)
    }

    // Create and store a new debounced function directly with fn
    const debouncedFn = useDebounceFn((cb) => cb(), delay, { rejectOnCancel: true })

    // Store the new debounced function in the map
    debounceMap.set(key, debouncedFn)

    // Cleanup on component unmount to prevent memory leaks
    onUnmounted(() => {
      if (debounceMap.has(key)) {
        debounceMap.get(key).cancel() // Cancel the debounce
        debounceMap.delete(key) // Remove from the map
      }
    })

    // Execute the debounced function with fn
    return debouncedFn(fn)
  }

  /**
   * Throttle check function with key and optional timeout, returns true/false based on throttle check.
   * - Checks if the provided `key` combined with cleaned query parameters (`query`) can execute
   *   based on the specified `timeout`.
   * - If new values are detected for the current `key`, any existing entries in `throttleMap` with
   *   the prefix `key + '_'` are deleted to ensure only the latest values are throttled.
   *
   * @param {string} key - Unique key identifier for throttling.
   * @param {Object} query - Parameters to uniquely identify this throttle instance (e.g., query params).
   * @param {number} [timeout=SHOP_REQUEST_TIMEOUT] - Timeout in milliseconds for throttling.
   * @returns {boolean} - Returns `true` if function execution is allowed, otherwise `false`.
   */
  const shouldExecuteWithThrottle = (key, query, timeout = SHOP_REQUEST_TIMEOUT) => {
    const searchParams = getCleanUrlSearchParams(query)
    const uniqueKey = key + '_' + searchParams.toString()
    const currentTime = Date.now()

    console.log('shouldExecuteWithThrottle', uniqueKey)
    // Check if the key is new or the timeout has passed
    if (!throttleMap.has(uniqueKey) || currentTime - throttleMap.get(uniqueKey) > timeout) {
      // Clean up any existing entries that start with the current `key + '_'
      for (const existingKey of throttleMap.keys()) {
        if (existingKey.startsWith(key + '_')) throttleMap.delete(existingKey)
      }

      throttleMap.set(uniqueKey, currentTime) // Update the timestamp

      return true // Allow function execution
    }

    return false // Block execution if throttled
  }

  // Reset throttle for all keys or a specific key
  const resetThrottleAll = () => {
    throttleMap.clear()
  }
  const resetThrottle = (key) => {
    throttleMap.delete(key)
  }

  return {
    debounceByKey,
    shouldExecuteWithThrottle,
    resetThrottleAll,
    resetThrottle,
  }
}
